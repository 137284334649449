import React from 'react'
import { useParams } from 'react-router-dom'

import PageLoading from '@src/components/PageLoading/PageLoading'
import { ProcessingFileState } from '@src/features/BulkDataImport/ProcessingFileState'
import { useGetImportSessionData } from '@src/api/bulkDataImport'
import {
  GenericEditableTable,
  CommonGenericEditableTableEntity,
  GenericTemporaryEntitiesTableProps,
} from '@src/features/GenericEditableTable/GenericEditableTable'

type BulkDataImportSessionProps<T> = Omit<
  GenericTemporaryEntitiesTableProps<T>,
  'sessionData' | 'refetchSessionData'
> & {
  entity: CommonGenericEditableTableEntity
  hiddenColumns?: Record<string, boolean>
}

export const BulkDataImportSessionV2 = <T,>(props: BulkDataImportSessionProps<T>) => {
  const params = useParams<{ id: string; type?: string }>()

  const { data, refetch } = useGetImportSessionData(props.apiEndpoint, params.id)

  if (!data) {
    return <PageLoading />
  }

  if (data.state.id === 'pending' || data.state.id === 'processing_file') {
    return <ProcessingFileState />
  }

  const disabled =
    data.state.id === 'success' ||
    data.state.id === 'failure' ||
    data.state.id === 'applying'

  return (
    <GenericEditableTable
      apiEndpoint={props.apiEndpoint}
      tableName={props.tableName}
      refetchSessionData={refetch}
      row={props.row}
      sessionData={data}
      actions={props.actions}
      disabled={disabled}
      entity={props.entity}
      variant="temporaryEntities"
      tableActions={params.type ? undefined : props.tableActions}
      hiddenColumns={props.hiddenColumns}
    />
  )
}
