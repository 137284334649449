import React, { useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { UploadSessionV2, UploadSessionV2Props } from './UploadSession'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { BulkDataImportUploadFileV2 } from '@src/features/BulkDataImport/BulkDataImportUploadFile'
import { ImportDataCategory } from '@src/interfaces/bulkDataImport'
import { OnboardingChecklistHeader } from '../OnboardingChecklistHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { TableActionsProps } from '@src/features/GenericEditableTable/GenericEditableTable'

type OnboardingBulkDataUploadProps<T> = {
  importRoute: string
  sessionRoute: string
  anyRoute: string
  apiEndpoint: string
  category: ImportDataCategory
  name: string
  tableActions?: (props: TableActionsProps) => React.ReactNode
  templateParams?: Record<string, string>
} & Pick<UploadSessionV2Props<T>, 'row' | 'tableName' | 'entity'>

const getHiddenColumns = (type?: string): Record<string, boolean> => {
  switch (type) {
    case 'team':
      return {
        specialisation: true,
        seniority: true,
        access_level: true,
      }
    case 'specialisation':
    case 'seniority':
      return {
        team: true,
        access_level: true,
      }
    case 'access_level':
      return {
        team: true,
        specialisation: true,
        seniority: true,
      }
    default:
      return {}
  }
}

const getHeaderTitle = (type?: string) => {
  switch (type) {
    case 'team':
      return 'Review bulk assigned team'
    case 'specialisation':
      return 'Review bulk assigned role'
    case 'seniority':
      return 'Review bulk assigned seniority'
    case 'access_level':
      return 'Review bulk assigned access group'
    default:
      return 'Import employees'
  }
}

export const OnboardingBulkDataUploadV2 = <T,>({
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  category,
  name,
  tableName,
  row,
  entity,
  tableActions,
  templateParams,
}: OnboardingBulkDataUploadProps<T>) => {
  const params = useParams<{ type?: string }>()

  const { title, hiddenColumns } = useMemo(
    () => ({
      title: getHeaderTitle(params?.type),
      hiddenColumns: getHiddenColumns(params?.type),
    }),
    [params?.type],
  )

  return (
    <PageWrapper>
      <OnboardingChecklistHeader
        title={title}
        backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT)}
      />
      <Switch>
        <Route exact path={importRoute}>
          <PageBody>
            <BulkDataImportUploadFileV2
              category={category}
              apiEndpoint={apiEndpoint}
              name={name}
              nextRoute={sessionRoute}
              templateParams={templateParams}
            />
          </PageBody>
        </Route>
        <Route exact path={sessionRoute}>
          <UploadSessionV2
            apiEndpoint={apiEndpoint}
            previousRoute={importRoute}
            tableName={tableName}
            row={row}
            entity={entity}
            tableActions={tableActions}
            hiddenColumns={hiddenColumns}
          />
        </Route>
        <Route exact path={anyRoute}>
          <InternalRedirect to={importRoute} />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
